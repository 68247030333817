import { difference, intersection } from 'lodash';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/build/cjs/types';
import { SettingsReader } from '../components/PackagePicker/DefaultSettingsAdapter';
import { PLANS_QUERY_PAGE_SIZE } from '../constants';
import { isTruthy } from './isTruthy';
import { removePlansWithIds, getIds } from './plan';

export function getOrderedVisiblePlans(plans: PublicPlan[], settings: SettingsReader) {
  const orderedPlans = getOrderedPlans(plans, settings);
  return getVisiblePlans(orderedPlans, settings);
}

export function getHiddenPlanIds(plans: PublicPlan[], settings: SettingsReader): string[] {
  return shouldUseVisiblePlansParam(settings)
    ? difference(getIds(plans), settings.uuidAsArray('visiblePlans'))
    : intersection(getIds(plans), settings.uuidAsArray('hiddenPlans'));
}

export function shouldUseVisiblePlansParam(settings: SettingsReader) {
  return settings.get('useVisiblePlans') || settings.uuidAsArray('visiblePlans').length > 0;
}

export function getOrderedPlans(plans: PublicPlan[], settings: SettingsReader): PublicPlan[] {
  const planOrder = settings.uuidAsArray('planOrder');
  const ordered = planOrder.map((id) => plans.find((p) => p.id === id)).filter(isTruthy);
  const unordered = removePlansWithIds(plans, planOrder);
  return [...ordered, ...unordered];
}

function getVisiblePlans(plans: PublicPlan[], settings: SettingsReader): PublicPlan[] {
  const hiddenPlanIds = getHiddenPlanIds(plans, settings);
  return removePlansWithIds(plans, hiddenPlanIds).slice(0, PLANS_QUERY_PAGE_SIZE);
}
